﻿@downloadNounSmall: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQffAhgSBQSlaW8IAAAAxklEQVQ4y82SLQ7CQBCF3ywILoEhKGRrEZwBgeE2BMMFMCR1CMIJcBgECRKDRZGgEDVQeLhN25ltsgmCT+3OmzfZnycIwC4SdEqFGw5SINieMGedE8LwSIspIAFD3yw/5A5uGMNeSETRNmoFzvgAIFLrtJq11xZadMaMt189tWgZpOkODpH8xMCGnXqlK0cceK3HlJdqg37WWWmYMKvL1j+svCHTIszEzAGAS0sKZWmLFsaWEB0+hzyq/yUcYtIchkpodvg/voCpNtx3IDzTAAAAAElFTkSuQmCC';
@deleteSmall: 'data:image/png;base64,R0lGODlhGAAYAMIFAPr6+vv7+/z8/P39/f7+/v///////////yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAcALAAAAAAYABgAAAN/eCqh/jCIF4YYFsaL27FYpylSmB3cxWlACiqYGnuOux6ESuTvsuu5muAnGBIaxmISQkwWm7+RxUmNjlDQ3DN4VWir3O4CPBHXskUzDP00D9jL61s7L9YvUuJ9v4PwuXpEDn9lY3xedFYPf14cb4VMjlyOJ3IheDAxajEDap4HCQA7';

// Colors
@text-emphasis-color: hsl(0, 0%, 100%);
@some-navigation-color: #563d7c;
@emphasis-color: hsl(102, 87%, 50%);
@panel-success-color: hsl(120, 39%, 54%);
@instruction-color: hsl(98, 100%, 73%);
@highlight-color: hsl(42, 87%, 50%);
@error-color: hsl(35, 87%, 62%);
@validation-error-color: hsl(0, 100%, 50%);
@log-failure-color: hsl(0, 65%, 58%);
@panel-info-color: hsl(194, 66%, 61%);
@show-all-color: lighten(@panel-info-color, 22);
@target-color: lightgray;
@background-color: hsl(120, 100%, 7%);
@text-shadow-color: hsl(0, 0%, 7%);

@font-face {
	font-family: 'orbitron-medium';
	src: url('../fonts/orbitron-medium.woff') format('woff');
}

.clickable{
	cursor: pointer;
	pointer-events: all;
}
.nonClickable {
	cursor: default;
}

body {
	padding-top: 50px;
	padding-bottom: 20px;
}

// Set padding to keep content from hitting the edges
.body-content {
	padding-left: 15px;
	padding-right: 15px;
}

//	Override the default bootstrap behavior where horizontal description lists 
//	will truncate terms that are too long to fit in the left column 
.dl-horizontal {
	dt {
		white-space: normal;
	}
}

.sidebar {
	&.affix {
		position: static;
	}

	.nav {
		> li {
			> a {
				display: block;
				padding: 4px 20px;
				font-size: 13px;
				font-weight: 500;

				&:hover, &:focus {
					padding-left: 19px;
					text-decoration: none;
					background-color: transparent;
					border-left: 1px solid @some-navigation-color;
				}
			}

			&.active, &:hover, &:focus {
				> a {
					padding-left: 18px;
					font-weight: 700;
					background-color: transparent;
					border-left: 2px solid @some-navigation-color;
				}
			}
		}

		.nav {
			> li {
				> a {
					padding-top: 1px;
					padding-bottom: 1px;
					padding-left: 30px;
					font-size: 12px;
					font-weight: 400;

					&:hover, &:focus {
						padding-left: 29px;
					}
				}

				&.active, &:hover, &:focus {
					> a {
						padding-left: 28px;
						font-weight: 500;
					}
				}
			}
		}
	}

	ul {
		li {
			a {
				color: @emphasis-color;
				font-weight: 900 !important;
				font-family: 'orbitron-medium',Arial, Helvetica, sans-serif;
				font-size: 32px !important;
				display: block;

				span {
					color: @text-emphasis-color;
					font-family: Helvetica;
					font-weight: Bold;
					font-size: 14px;
					display: block;
				}
			}

			&.active {
				a {
					color: @instruction-color;
					font-weight: 950 !important;
					font-family: 'orbitron-medium', Arial, Helvetica, sans-serif;
					font-size: 32px !important;
					display: block;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.sidebar {
		padding-left: 20px
	}
}

@media (min-width: 992px) {
	.sidebar .nav > .active > ul {
		display: block;
	}

	.sidebar.affix {
		position: fixed;
		top: 70px;
	}
}

.searchTable{
	overflow: auto;
}

.highlightedRow {
	background-color: @emphasis-color !important;
	color: @text-emphasis-color;
	font-weight: bolder;
}

.mainSpaDiv,
#Home,
#Manage,
#SplashScreen
 {
	min-height: 500px;
}

#Target section {
	section {
		margin: 2em;
	}
}

.targetUser-serialListing {
	span {
		color: @error-color;
		margin: 0;
		margin-right: .5em;
		margin-left: 0;
	}
}

.validationMessage {
	color: @validation-error-color;
	font-weight: bold;
	font-size: smaller;
}

#packageTable {
	border: 4px hidden;
	border-spacing: 10px;

	&.invalid {
		border: 4px solid @validation-error-color !important;
		border-spacing: 10px;
	}

	tr {
		td {
			cursor: default;
		}
	}
}

a.conditionalLink {
	font-size: smaller;
	text-decoration: underline;
}

.targetButton {
	background-color: @emphasis-color;
	border: 2px solid @emphasis-color;
	text-shadow: 2px 3px 1px @text-shadow-color;
	color: @target-color;
	font-weight: bolder;
	float: right;
}
.targetLabel {
	float: right;
	margin-right: 5px;
	padding-top: 10px;
}

.targetFileAssigned {
	background-color: @background-color !important;
	opacity: .4;
	z-index: 10;
	color: lightgray;
}

.targetInstructions {
	color: @instruction-color;
	text-decoration:underline;

	label {
		color: @emphasis-color;
		font-size: medium;
	}
}

.emLabel {
	color: @emphasis-color;
	font-size: large;
}

.exception-panel {
	max-height: 600px;
	overflow: auto;
}

div.log-panel {
	a:hover{
		text-decoration: none;
	}
}

.panel {
	margin: 1px;
}

.panel-heading {
	 a {
		 .collapsed {
			 &:after {
				 content:"\e080";
			 }
		 }
		 &:after {
			font-family:'Glyphicons Halflings';
			content:"\e114";
			float: right;
			color: grey;
		}
	}
}

.panel-title {
	font-size: 13px;
}

.panel-body {
	a {
		span {
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	label {
		color: @panel-info-color;
		font-weight: bolder;
		padding-right: 10px;
	}
}

.log-panel-failure {
	span.log-panel-icon:before {
		font-family:'Glyphicons Halflings';
		content:"\e107";
		float: left;
		color: @log-failure-color;
	}

	span.log-panel-message {
		color: @log-failure-color;
	}
}

.log-panel-warning {
	span.log-panel-icon:before {
		font-family:'Glyphicons Halflings';
		content:"\e101";
		float: left;
		color: @error-color;
	}
}

.log-panel-info {
	span.log-panel-icon:before {
		font-family:'Glyphicons Halflings';
		content:"\e086";
		float: left;
		color: @panel-info-color;
	}
}

.log-panel-success {
	span.log-panel-icon:before {
		font-family:'Glyphicons Halflings';
		content:"\e089";
		float: left;
		color: @panel-success-color;
	}
}

.assign-button-up {
	&:before {
		font-family: 'Glyphicons Halflings';
		font-size: large;
		content: "\e253";
		float: left;
		color: @target-color;
		background-color: transparent;
		border: 0;
	}
}

.assign-slotnum {
	padding-left: 5px;
	padding-right: 5px;
	vertical-align: top;
}

.assign-button-down:before {
	font-family: 'Glyphicons Halflings';
	font-size: large;
	content: "\e252";
	float: left;
	color: @target-color;
	background-color: transparent;
	border: 0;
}

.assign-button-add:before {
	font-family: 'Glyphicons Halflings';
	font-size: large;
	content: "\e081";
	float: left;
	color: @target-color;
	background-color: transparent;
	border: 0;
}

.assign-button-close:before {
	font-family: 'Glyphicons Halflings';
	content: "\e083";
	font-size: large;
	float: left;
	color: @target-color;
	background-color: transparent;
	border: 0;
}

.buttonSpace {
	/* margin-left: 39px; */
	opacity: 0.1;
}

.sort-asc:after {
	font-family:'Glyphicons Halflings';
	content:"\e252";
	float: right;
	color: @target-color;
}

.sort-desc:after {
	font-family:'Glyphicons Halflings';
	content:"\e253";
	float: right;
	color: @target-color;
}

.manage-package-info {
	color: darken(@target-color, 35%);
	float: right;

	> span {
		color: @error-color;
		margin-left: 10px;
	}
}

.serialList {
	max-height: 100px;
	overflow-y: auto;
}

#StockFiles {
	.download-link {
		width: 24px;
		height: 24px;
		padding: 0;
		border: 0;
		background: url(@downloadNounSmall) /*images/download_noun_small_12584.png*/ no-repeat transparent;
	}

	.delete-link {
		width: 24px;
		height: 24px;
		padding: 0;
		border: 0;
		background: url(@deleteSmall) no-repeat transparent;
	}

	a {
		padding: 0 20px;
		vertical-align: top;
		cursor: pointer;
	}

	td.fileMissing:after {
		color: @validation-error-color;
		content: " (missing)";
		font-size: smaller;
	}
}

div[role="main"] > p {
	text-align: center;
	font-size: larger;
}

.meny-arrow {
	position: fixed;
	z-index: 10;
	border: 10px solid transparent;
	-webkit-transition: opacity 0.4s ease 0.4s;
	-moz-transition: opacity 0.4s ease 0.4s;
	-ms-transition: opacity 0.4s ease 0.4s;
	-o-transition: opacity 0.4s ease 0.4s;
	transition: opacity 0.4s ease 0.4s;

	.meny-left & {
		left: 14px;
		top: 50%;
		margin-top: -16px;
		border-left: 16px solid darken(@target-color, 35%);
	}

	.meny-right & {
		right: 14px;
		top: 50%;
		margin-top: -16px;
		border-right: 16px solid darken(@target-color, 35%);
	}
	.meny-top & {
		left: 50%;
		top: 14px;
		margin-left: -16px;
		border-top: 16px solid darken(@target-color, 35%);
	}
	.meny-bottom & {
		left: 50%;
		bottom: 14px;
		margin-left: -16px;
		border-bottom: 16px solid darken(@target-color, 35%);
	}
	.meny-active & {
		opacity: 0;
		-webkit-transition: opacity 0.2s ease;
		-moz-transition: opacity 0.2s ease;
		-ms-transition: opacity 0.2s ease;
		-o-transition: opacity 0.2s ease;
		transition: opacity 0.2s ease;
	}
}

.table-header-hover thead tr:hover > th {
	.clickable;
}

.hover-pointer {
	.clickable;
}

.showAllButton {
	border: 2px solid @show-all-color;
	color: @target-color;
	font-weight: bolder;
}

.currentFile {
	color: @panel-success-color;
}

.pendingFile{
	color: @log-failure-color;
}

.text-ellipsis {
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}

input[type="number"] {
	-moz-appearance:textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
